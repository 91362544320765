













































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import debounce from 'lodash/debounce';
import camelcaseKeys from 'camelcase-keys';
import { toasts } from '@/utils/toasts';
import i18n from '@/i18n';

import { constants } from '../../../utils/constants';
import {
  getAvailableAccounts,
  addProductClassification,
  editProductClassification,
} from './product-classification-api';

@Component
export default class extends Vue {
  items: Array<any> = [];
  selectedItem: any | null = null;
  actionType: string = 'add';

  totalConturi: Array<any> = [];
  newDenumire: string = '';
  newTip: string = constants.productClassTypes.product;
  newContGestiuneSelection: any | null = null;
  newContCheltuialaSelection: any | null = null;
  newContVenitSelection: any | null = null;
  newContDiferentePretSelection: any | null = null;
  conturiGestiune: Array<any> = [];
  conturiCheltuiala: Array<any> = [];
  conturiVenit: Array<any> = [];
  conturiDiferentePret: Array<any> = [];

  tipOptions: Array<any> = [
    { text: 'Produs', value: constants.productClassTypes.product },
    { text: 'Serviciu', value: constants.productClassTypes.service },
  ];

  created() {
    this.initialize();
  }

  async initialize() {
    await this.loadAccounts();
  }

  onTipChange(value) {
    this.newContGestiuneSelection = null;
    this.newContCheltuialaSelection = null;
    this.newContVenitSelection = null;
    this.newContDiferentePretSelection = null;

    if (value === constants.productClassTypes.product) {
      this.filterConturiGestiune(this.totalConturi, this.newTip, null);
      this.filterConturiCheltuiala(this.totalConturi, constants.productClassTypes.product, null);
      this.filterConturiVenit(this.totalConturi, constants.productClassTypes.product, null);
      this.filterConturiDiferentePret(this.totalConturi, constants.productClassTypes.product, null);
    } else {
      this.filterConturiGestiune(this.totalConturi, this.newTip, null);
      this.filterConturiCheltuiala(this.totalConturi, constants.productClassTypes.service, null);
      this.filterConturiVenit(this.totalConturi, constants.productClassTypes.service, null);
      this.filterConturiDiferentePret(this.totalConturi, constants.productClassTypes.service, null);
    }
  }

  isEditMode() {
    return this.actionType === 'edit';
  }

  get modalTitle() {
    if (this.actionType === 'add') {
      return i18n.t('applicationSettings.clasificareProduse.modalTitleAdd');
    }
    if (this.actionType === 'edit') {
      return i18n.t('applicationSettings.clasificareProduse.modalTitleEdit');
    }
    return i18n.t('applicationSettings.clasificareProduse.modalTitleInvalid');
  }

  showProductField() {
    return this.newTip === constants.productClassTypes.product;
  }
  showServiceField() {
    return this.newTip === constants.productClassTypes.service;
  }

  debouncedSearchConturiGestiune = debounce(this.searchCoduriGestiune, 75, { maxWait: 150 });
  debouncedSearchConturiCheltuiala = debounce(this.searchCoduriCheltuiala, 75, { maxWait: 150 });
  debouncedSearchConturiVenit = debounce(this.searchCoduriVenit, 75, { maxWait: 150 });
  debouncedSearchConturiDiferentaPret = debounce(this.searchCoduriDiferentePret, 75, { maxWait: 150 });

  onRemoveContGestiune() { this.newContGestiuneSelection = null; }
  onRemoveContCheltuiala() { this.newContCheltuialaSelection = null; }
  onRemoveContVenit() { this.newContVenitSelection = null; }
  onRemoveContDiferentePret() { this.newContDiferentePretSelection = null; }

  searchCoduriGestiune(query: string) {
    this.filterConturiGestiune(this.totalConturi, this.newTip, query);
  }
  searchCoduriCheltuiala(query: string) {
    this.filterConturiCheltuiala(this.totalConturi, this.newTip, query);
  }
  searchCoduriVenit(query: string) {
    this.filterConturiVenit(this.totalConturi, this.newTip, query);
  }
  searchCoduriDiferentePret(query: string) {
    this.filterConturiDiferentePret(this.totalConturi, this.newTip, query);
  }

  filterConturiGestiune(total, productType, query) {
    const regex = (productType === constants.productClassTypes.product) ? /^3/g : /^3/g;
    this.conturiGestiune = total.filter((e) => regex.test(e.numar));

    if (query && query.length > 1) {
      this.conturiGestiune = this.conturiGestiune.filter((e) => e.label.toLowerCase().includes(query.toLowerCase()));
    }
  }
  filterConturiCheltuiala(total, productType, query) {
    const regex = (productType === constants.productClassTypes.product) ? /^6|^711/g : /^6/g;
    this.conturiCheltuiala = total.filter((e) => regex.test(e.numar));

    if (query && query.length > 1) {
      this.conturiCheltuiala = this.conturiCheltuiala.filter((e) => e.label.toLowerCase().includes(query.toLowerCase()));
    }
  }
  filterConturiVenit(total, productType, query) {
    const regex = (productType === constants.productClassTypes.product) ? /^7/g : /^7/g;
    this.conturiVenit = total.filter((e) => regex.test(e.numar));

    if (query && query.length > 1) {
      this.conturiVenit = this.conturiVenit.filter((e) => e.label.toLowerCase().includes(query.toLowerCase()));
    }
  }
  filterConturiDiferentePret(total, productType, query) {
    const regex = (productType === constants.productClassTypes.product) ? /^3/g : /^3/g;
    this.conturiDiferentePret = total.filter((e) => regex.test(e.numar));

    if (query && query.length > 1) {
      this.conturiDiferentePret = this.conturiDiferentePret.filter((e) => e.label.toLowerCase().includes(query.toLowerCase()));
    }
  }

  async onAddEditClassificationModalOk(event) {
    event.preventDefault();

    const item = {
      denumire: this.newDenumire.trim(),
      tip: this.newTip,
      clasificareProdusId: this.selectedItem?.clasificareProdusId,
      currentVersion: this.selectedItem?.currentVersion,
      contGestiuneId: this.newContGestiuneSelection?.internalId,
      contCheltuialaId: this.newContCheltuialaSelection?.internalId,
      contVenitId: this.newContVenitSelection?.internalId,
      contDiferentePretId: this.newContDiferentePretSelection?.internalId,
    };

    if (!this.validateFields(item)) {
      return;
    }

    try {
      if (this.actionType === 'add') {
        await addProductClassification(item);
      } else {
        await editProductClassification(item);
      }
      (<any>(this.$refs.modal)).hide();
      this.$emit('onSubmitProductClassificationEvent');
    } catch (err) {
      toasts.error('Eroare la salvarea modificărilor');
    }
  }

  isDenumireDuplicate(item) {
    const duplicate = this.items
      .find((e) => e.denumire.toLowerCase() === item.denumire.toLowerCase()
        && e.tip === item.tip);
    if (!duplicate) return false;
    if (!item.clasificareProdusId) return true;
    if (item.clasificareProdusId === duplicate.clasificareProdusId) return false;

    return true;
  }

  validateFields(item) {
    const errors: Array<string> = [];

    if (!item.denumire) {
      errors.push('Clasificarea nu are denumire');
    }
    if (this.isDenumireDuplicate(item)) {
      errors.push('O clasificare cu această denumire există deja');
    }
    if (!item.tip) {
      errors.push('Clasificarea are nevoie de un tip');
    }
    if (item.tip === constants.productClassTypes.product) {
      if (!item.contGestiuneId) {
        errors.push('Clasificarea nu are cont gestiune');
      }
      if (!item.contCheltuialaId) {
        errors.push('Clasificarea nu are cont cheltuială');
      }
      if (!item.contVenitId) {
        errors.push('Clasificarea nu are cont venit');
      }
    } else if (item.tip === constants.productClassTypes.service) {
      if (!item.contCheltuialaId && !item.contVenitId) {
        errors.push('Clasificarea are nevoie de cel puțin un cont cheltuială sau un cont venit');
      }
    } else {
      errors.push('Clasificarea are nevoie de un tip valid');
    }

    if (errors.length) {
      this.showErrorsToast(errors, 'Eroare la validare');
      return false;
    }

    return true;
  }

  showErrorsToast(errors, toastId: string|undefined) {
    const h = this.$createElement;
    const vNodesMsg = [
      h('p', {}, 'Trebuie corectate următorele erori:'),
      h(
        'ul',
        {},
        errors.map((e) => h('li', {}, e)),
      )];
    toasts.error(vNodesMsg, {
      title: 'Eroare de validare',
      id: toastId,
    });
  }

  async loadAccounts() {
    try {
      this.totalConturi = camelcaseKeys(await getAvailableAccounts());
      this.totalConturi = this.totalConturi.map((e) => ({ ...e, label: `${e.numar} - ${e.denumire}` }));
      this.filterConturiGestiune(this.totalConturi, this.newTip, null);
      this.filterConturiCheltuiala(this.totalConturi, this.newTip, null);
      this.filterConturiVenit(this.totalConturi, this.newTip, null);
      this.filterConturiDiferentePret(this.totalConturi, this.newTip, null);
    } catch (err) {
      toasts.error('Eroare la încărcarea conturilor!');
    }
  }

  resetFields() {
    this.newDenumire = '';
    this.newTip = constants.productClassTypes.product;
    this.newContGestiuneSelection = null;
    this.newContCheltuialaSelection = null;
    this.newContVenitSelection = null;
    this.newContDiferentePretSelection = null;
  }

  async showModal(items, editItem, actionType) {
    this.items = items;
    this.selectedItem = editItem;
    this.actionType = actionType;

    if (actionType === 'add') {
      this.resetFields();
    } else {
      this.newDenumire = editItem.denumire;
      this.newTip = editItem.tip;
      this.newContGestiuneSelection = this.totalConturi.find((e) => e.internalId === editItem.contGestiuneId);
      this.newContCheltuialaSelection = this.totalConturi.find((e) => e.internalId === editItem.contCheltuialaId);
      this.newContVenitSelection = this.totalConturi.find((e) => e.internalId === editItem.contVenitId);
      this.newContDiferentePretSelection = this.totalConturi.find((e) => e.internalId === editItem.contDiferentePretId);
    }

    await this.loadAccounts();
    (<any>(this.$refs.modal)).show();
  }
}

